<template>
  <v-row align="center" justify="center" class="mt-7">
    <v-btn
      :x-small="$vuetify.breakpoint.xs"
      :class="{ 'mt-n15': $vuetify.breakpoint.xs }"
      text
      :color="recording ? 'red' : 'blue'"
      @click="recording = !recording"
      >{{ buttonRecording }}<v-icon :x-small="$vuetify.breakpoint.xs">mdi-microphone</v-icon></v-btn
    >
    <h4
      class="font-weight-regular"
      :class="[
        { 'caption font-weight-regular': $vuetify.breakpoint.xs },
        { 'mt-n15': $vuetify.breakpoint.xs }
      ]"
    >
      или
    </h4>
    <input ref="audio" type="file" accept="audio/*" style="display: none" @change="uploadFile" />
    <v-btn
      :x-small="$vuetify.breakpoint.xs"
      text
      color="blue"
      :class="{ 'mt-n15': $vuetify.breakpoint.xs }"
      @click="inputFile"
      >Выбрать аудио<v-icon :x-small="$vuetify.breakpoint.xs">mdi-paperclip</v-icon>
    </v-btn>
    <audio controls :src="audioUrl" />
    <v-btn :disabled="audioUrl === 'nonexistent'" text icon color="red" @click="deleteAudio"
      ><v-icon>mdi-delete</v-icon></v-btn
    >
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Microphone',
  props: {
    voiceSrc: {
      type: String,
      required: true
    }
  },
  data: () => ({
    recording: false,
    constraints: { audio: true },
    mediaRecorder: null,
    audioUrl: 'nonexistent',
    audioBlob: null,
    audio: null
  }),
  computed: {
    ...mapState({
      stream: state => state.shared.stream
    }),
    buttonRecording() {
      return this.recording ? 'стоп' : 'начать запись'
    }
  },
  watch: {
    recording: {
      deep: true,
      handler(item) {
        if (item) {
          this.$store.dispatch('getMedia', this.constraints).then(() => {
            this.createMediaRecorder()
            this.mediaRecorder.start()
          })
        } else {
          this.mediaRecorder.stop()
          const tracks = this.stream.getTracks()
          tracks[0].stop()
        }
      }
    }
  },
  created() {
    this.audioUrl = this.voiceSrc
  },
  methods: {
    createMediaRecorder() {
      this.mediaRecorder = new MediaRecorder(this.stream)
      this.mediaRecorder.addEventListener('dataavailable', event => {
        this.audioBlob = new Blob([event.data], {
          type: 'audio/mpeg'
        })
        if (this.audioBlob.size > 4 * 1024 * 1024) {
          this.$store.commit('setError', 'Длительность записи не должна превышать 10 минут')
          setTimeout(() => this.$store.commit('clearError'), 5000)
          this.audioUrl = 'nonexistent'
        } else {
          this.audioUrl = URL.createObjectURL(this.audioBlob)
          this.$emit('uploadAudioUrl', this.audioUrl)
          this.$emit('uploadAudioBlob', this.audioBlob)
        }
      })
    },
    inputFile() {
      this.$refs.audio.click()
    },
    uploadFile() {
      this.audio = this.$refs.audio.files[0]
      if (this.audio.size > 4 * 1024 * 1024) {
        this.$store.commit('setError', 'Размер файла не должен превышать 4000КБ')
        setTimeout(() => this.$store.commit('clearError'), 5000)
      } else {
        this.$emit('uploadAudioBlob', this.audio)
        const reader = new FileReader()
        reader.onload = () => {
          this.$emit('uploadAudioUrl', reader.result)
          this.audioUrl = reader.result
        }
        reader.readAsDataURL(this.audio)
      }
    },
    deleteAudio() {
      this.audioBlob = null
      this.audio = null
      this.audioUrl = 'nonexistent'
      this.$emit('uploadAudioUrl', 'nonexistent')
    }
  }
}
</script>

<style scoped lang="stylus">
audio {
  max-width:15rem;
  max-height:2rem;
}
</style>
